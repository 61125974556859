import "./styles.scss"

export default function Amount({ amount, symbol }) {
  const icon = `https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/32/icon/${symbol.toLowerCase()}.png`

  return (
    <div className="cp-container">
      <img
        className="icon"
        src={icon}
        alt={`${icon}-ico`}
      />
      <div className="name">{amount}</div>
    </div>
  )
};
