import React, { useState, useEffect } from 'react'

// Redux
import { useSelector } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO Colors
import colors from 'assets/theme/base/colors';

// Data
import { exchangesData } from "data/exchanges";

// Copy trade components
import ExchangeCard from './ExchangeCard';

// Translate
import { useTranslation } from "react-i18next";

const Bot = () => {
    const { t } = useTranslation();
    const keysData = useSelector((state) => state.keys.data);
    const botsData = useSelector((state) => state.bots.data);
    const [activeExchanges, setExchanges] = useState([]);
    const [state, setState] = useState(!!botsData.find((bot) => bot.bot_id === 1));

    useEffect(() => {
        setState(!!botsData.find((bot) => bot.bot_id === 1));
        if (keysData.length) {
            const activeExchanges = keysData.filter(({ isActive }) => isActive)
            setExchanges(activeExchanges.map(({ exchange }) => {
                const { label, img, value } = exchangesData.find(({ value }) => value === exchange);
                const bot = botsData.find((bot) => bot.exchange === exchange && bot.bot_id === 1);
                return { value, label, img, suscription_id: bot?.suscription_id || null, amount: bot?.amount || "" }
            }))
        }
    }, [keysData, botsData, setExchanges]);

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox
                component="form"
                p={3}
                height="100%"
                bgColor={state ? "info" : "white"}
                variant="gradient"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                >
                    <SoftTypography variant="body2" color={state ? "white" : "text"} fontWeight="bold">
                        {t(state ? "On" : "Off")}
                    </SoftTypography>
                </SoftBox>
                {
                    state ? (
                        <QueryStatsSharpIcon
                            fontSize="large"
                            sx={{
                                bgcolor: colors.white.main,
                                p: 1,
                                height: "50px",
                                width: "50px",
                                borderRadius: 50
                            }}
                        />
                    ) : (
                        <QueryStatsSharpIcon
                            fontSize="large"
                            color="white"
                            sx={{
                                bgcolor: colors.dark.main,
                                p: 1,
                                height: "50px",
                                width: "50px",
                                borderRadius: 50
                            }}
                        />
                    )
                }
                <SoftBox mt={1} lineHeight={1}>
                    <SoftTypography
                        variant="body2"
                        color={state ? "white" : "text"}
                        textTransform="capitalize"
                        fontWeight="medium"
                    >
                        {t("Bot")}
                    </SoftTypography>
                    <SoftTypography variant="caption" color={state ? "white" : "text"}>
                        {t("To utilize the Bot, ensure that you have")}{" "}
                        <strong>{t("activated at least one exchange")}</strong>{" "}
                        {t("from the list. The bot will operate on the selected pairs across your chosen exchanges. Please note that the minimum amount required to activate the bot is")} {" "}
                        <strong>{t("500 USD")}</strong>.{" "}
                        {t("The bot will replicate trading strategies on your behalf, automatically mirroring trades based on the selected pairs on your active exchanges")}.
                    </SoftTypography>
                    <Divider />
                </SoftBox>
                <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                >
                    {activeExchanges.map((exchahge, index) => (
                        <ExchangeCard
                            key={index}
                            exchange={exchahge}
                            isBotActive={state}
                            setBotActive={setState}
                        />
                    ))}
                </SoftBox>
            </SoftBox>
        </Card>
    )
};

export default Bot;