// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cp-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cp-container .name {
  margin-left: 8px;
  margin-top: 4px;
  font-weight: 700;
}
@media (max-width: 767.95px) {
  .cp-container {
    justify-content: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/componentsCustom/TableItems/ProfitPerOne/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;AACR;AAEI;EAXJ;IAYQ,2BAAA;EACN;AACF","sourcesContent":[".cp-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    .name {\r\n        margin-left: 8px;\r\n        margin-top: 4px;\r\n        font-weight: 700;\r\n    }\r\n\r\n    @media (max-width:767.95px) {\r\n        justify-content: flex-start;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
