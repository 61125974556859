import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { jwtDecode } from "jwt-decode";
// react-redux
import { useSelector, useDispatch } from "react-redux";
import { logoutSesion } from "redux/actions/currentUser";
// react-router components
import { Routes, Route, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
// Soft UI Dashboard React themes
import theme from "assets/theme";
// Soft UI Dashboard React routes
import routesFromUser from "routes";
// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav } from "context";
import FloatingVideo from "componentsCustom/FloatingVideo";
import axios from "axios";
import { ROUTES } from "constants";
import Error404 from "layouts/Common/error/404";
import favicon_without_text from "assets/logos/favicon-without-text.png";
import { useTranslation } from "react-i18next";
import { executeMatomo } from "utils/executeMatomo";
import { CLIENT_NAME } from "apiconfig";
import clientConfig from "utils/clientConfig";
import Sockets from "componentsCustom/Sockets/index";
import "./utils/i18n";
import "./index.css";
import { setInformativeMessage } from "redux/actions/informativeMessage";
import useUserLanguage from "utils/useUserLanguage";
import useAssets from "hooks/useAssets";

export function App() {
  const {
    jwtToken,
    role: userRole,
    language: lng,
  } = useSelector((state) => state.currentUser.data);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [controller, dispatch] = useSoftUIController();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const dispatchRedux = useDispatch();
  const userLanguage = useUserLanguage();
  const { favicon } = useAssets()

  const currentDate = new Date();
  const currentTimeSeconds = Math.floor(currentDate.getTime() / 1000);

  const toggleVideoPlaying = () => setIsVideoPlaying((prevState) => !prevState);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (jwtToken) {
      const decoded = jwtDecode(jwtToken);
      const expirationTimeSeconds = Math.floor((decoded.exp * 1000) / 1000);
      if (currentTimeSeconds > expirationTimeSeconds)
        dispatchRedux(logoutSesion());
    }
  }, [currentTimeSeconds]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response && err.response.status === 401) {
        const { message } = err.response.data;
        dispatchRedux(logoutSesion());
        dispatchRedux(
          setInformativeMessage({
            success: false,
            message,
          })
        );
        navigate(ROUTES.LOGIN);
      }
      return Promise.reject(err);
    }
  );

  useEffect(() => {
    if (clientConfig.useMatomo) executeMatomo();
  }, [lng]);

  useEffect(() => {
    i18n.changeLanguage(lng || userLanguage);
  }, [lng, userLanguage]);

  useEffect(() => {
    document.title = CLIENT_NAME;
    const primaryColor = localStorage.getItem("primaryColor");
    const primaryColorGradientEnd = localStorage.getItem("primaryColorGradientEnd");
    if (!primaryColor || !primaryColorGradientEnd) {
      localStorage.setItem("primaryColor", clientConfig?.primaryColor || "#cb0c9f");
      localStorage.setItem("primaryColorGradientEnd", clientConfig?.primaryColorGradientEnd || "#2152ff");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    link.href = favicon;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isVideoPlaying && (
        <FloatingVideo
          isVideoPlaying={isVideoPlaying}
          toggleVideoPlaying={toggleVideoPlaying}
        />
      )}
      <div style={{ margin: "auto", maxWidth: "100%" }}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sockets />
            <Sidenav
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
              selectedVideo={selectedVideo}
              setSelectedVideo={setSelectedVideo}
              videosData={[]}
              color={sidenavColor}
              brand={favicon_without_text}
              brandName="CTRADING"
              routes={routesFromUser(userRole)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          {getRoutes(routesFromUser(userRole))}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
