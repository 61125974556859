// Soft UI Dashboard React components
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TradesTable from "./components/TradesTable/index";
import { getOrdersFromExchanges } from "redux/actions/orders";
import Loader from "components/Loader";
import RefreshButton from "./components/RefreshButton/index";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
// Translate
import { useTranslation } from 'react-i18next';
import SoftBox from "components/SoftBox";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useIsUserFreemium } from "utils/useUserSubscriptionStatus";
import { useIsUserPremium } from "utils/useUserSubscriptionStatus";
import FuturesTable from "./components/FuturesTable";

function Orders() {
  const { t } = useTranslation();
  const isUserFreemium = useIsUserFreemium();
  const isUserPremium = useIsUserPremium();
  const dispatch = useDispatch()
  const { spot, futures } = useSelector(state => state.orders.data.trades)
  const tradesLoading = useSelector(state => state.orders.loading)
  const [orderType, setOrderType] = useState("spot");
  const [selectedOrderType, setSelectedOrderType] = useState("closed")
  const selectedTrades = spot?.filter(trade => trade.status === selectedOrderType)
  const showFutures = !isUserFreemium && !isUserPremium;

  const getInitialTrades = () => dispatch(getOrdersFromExchanges())
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (event) => {
    const value = event.target.id
    if (orderType === 'futures' && !value) {
      setMenu(null)
      return;
    }
    setSelectedOrderType(value || selectedOrderType)
    setOrderType('spot')
    setMenu(null)
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu} id="closed">Closed</MenuItem>
      <MenuItem onClick={closeMenu} id="open">Opened</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <WalletsAddedGuard>
        <SoftBox id="ordersSection">
          <RefreshButton isLoading={tradesLoading} action={getInitialTrades} />
          <SoftButton variant="text" color="dark" onClick={openMenu} className={orderType === 'spot' ? "selected" : "notSelected"}>
            spot {orderType === 'futures' ? "" : `(${selectedOrderType})`}&nbsp;
            <Icon>expand_more</Icon>
          </SoftButton>
          {renderMenu}
          {showFutures && <SoftButton id="futuresOrders" key="futures" className={orderType === 'futures' ? "selected" : "notSelected"} variant="text" color="dark" value="futures" onClick={(e) => setOrderType(e.target.value)}>{t('futures')}</SoftButton>}
          {tradesLoading && <Loader />}
          {orderType === 'spot' ? <TradesTable trades={selectedTrades} selectedOrderType={selectedOrderType} /> : <FuturesTable trades={futures} />}
        </SoftBox>
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}

export default Orders;