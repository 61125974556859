import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { setInformativeMessage } from "redux/actions/informativeMessage";
import { signIn } from "api/auth";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import IllustrationLayout from "layouts/Common/IllustrationLayout";

// Authentication layout components
import PasswordInputToggle from "examples/Inputs/PasswordInputToggle";
import RememberMeSwitch from "examples/Buttons/RememberMeSwitch";
import ForgotLinkBasic from "examples/Buttons/ForgotLinkBasic";
import ActionButton from "examples/Buttons/ActionButton";
import Separator from "examples/Separator";
import RedirectButton from "examples/Buttons/RedirectButton";

// Constants
import { ROUTES } from "constants";

// Utils
import { loginForm } from "./schemas/form";


export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateInputs = () => {
    const { email, password } = formData;
    return !password || !email ? false : true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const validation = validateInputs();
    if (validation) {
      const url = await signIn(formData);
      url && navigate(url);
    } else {
      dispatch(
        setInformativeMessage({
          success: false,
          message: "Email and Password are required.",
        })
      );
    }
    setLoading(false);
  };

  const handleGoogleLogin = () => {
    window.location.href = 'http://localhost:3000/auth/google';
  }

  return (
    <IllustrationLayout
      title="Welcome back!"
      description="Enter your email and password to sign in"
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        {loginForm.map((field) => (
          <SoftBox key={field.key} mb={1} lineHeight={1.25}>
            {field.name === "password" ? (
              <PasswordInputToggle
                {...field}
                id="password"
                onChange={handleChange}
              />
            ) : (
              <SoftInput {...field} id="email" onChange={handleChange} />
            )}
          </SoftBox>
        ))}
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <RememberMeSwitch
            value={formData.rememberMe}
            onChange={handleChange}
          />
          <ForgotLinkBasic />
        </SoftBox>
        <SoftBox mt={1} mb={1}>
          <ActionButton loading={loading} label="sign in" />
        </SoftBox>
        <SoftBox mt={1} mb={1}>
          <button onClick={handleGoogleLogin}>Google Login</button>
        </SoftBox>
        <Separator />
        <RedirectButton route={ROUTES.REGISTER} label="sign up" />
      </SoftBox>
    </IllustrationLayout>
  );
}
