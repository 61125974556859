export const CLIENTS_CONFIG = [
    {
        name: "community-dev-front-carlos",
        url: "https://community-dev-front-carlos.tradingplatformcustom.com",
        email: "info@ctrading.io",
        emailName: "CTPlus-V3",
        domain: "tradingplatformcustom.com",
        useMatomo: false,
        matomoId: "",
        matomoCookieDomain: "",
        useTrading: true,
        useArbitrage: true,
        useReferrals: true,
        needToApproveUsers: false,
        usePremiumModel: true,
        isDemo: true,
        useUnlock: false,
        unlockNetwork: "",
        unlockContracts: "",
        usePostHog: false,
        useBots: true,
        primaryColor: "#21d4fd",
        primaryColorGradientEnd: "#2152ff"
    }
]
