import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";

// CONSTANTS
import { STATUS } from "constants/fetchStatus";

import AccordionVideo from "./Accordion";
import clientConfig from "utils/clientConfig";
import Avatar from "./Avatar";
import Action from "./Buttons/Action";
import Close from "./Buttons/Close";

const { gradients } = colors;

export default function PlayList({
  videos,
  showPlayList,
  setShowPlayList,
  setCurrentVideo,
  toggleVideoPlaying,
}) {
  const { data, status } = videos;
  const [videosBySection, setVideosBySection] = useState({})

  const handlePlay = () => {
    const index = 0;
    const { url } = data[index];
    setShowPlayList(!showPlayList);
    setCurrentVideo(url);
  };

  useEffect(() => {
    data && setVideosBySection(data.reduce((acc, video) => {
      if (!acc[video.section]) {
        acc[video.section] = [];
      }
      acc[video.section].push(video);
      return acc;
    }, {}));
  }, [data, setVideosBySection])


  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        height="100%"
        bgColor="info"
        variant="gradient"
        sx={{ position: "relative" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SoftBox
          width="100%"
          display="flex"
          justifyContent="end"
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            px: "0.5vw",
            py: "1vh",
          }}
        >
          <Close handle={toggleVideoPlaying} />
        </SoftBox>
        <Avatar playList />
        <SoftBox px="1vw" display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <SoftBadge badgeContent={`${clientConfig.emailName} play list`} size="xs" container />
          <Action handle={handlePlay} />
        </SoftBox>
        <SoftBox
          height="70%"
          px="1vw"
          py="1vw"
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflowX: "hidden",
            "& ul": { padding: 0 },
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: gradients.info.main,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors.white.main,
            },
          }}
        >
          <Divider light sx={{ mt: -2 }} />
          {status === STATUS.ERROR && (
            <SoftTypography
              sx={{ display: "inline" }}
              component="span"
              variant="button"
              color="white"
            >
              Oops, something went wrong!
            </SoftTypography>
          )}
          {
            videosBySection && Object.keys(videosBySection).map((section, index) => (
              <AccordionVideo
                key={index}
                setShowPlayList={setShowPlayList}
                showPlayList={showPlayList}
                setCurrentVideo={setCurrentVideo}
                section={section}
                videos={videosBySection[section]}
                index={index}
              />
            ))
          }
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
