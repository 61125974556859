// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trading-view {
  min-height: 92vh !important;
  height: 92vh !important;
}

.minimized-modal {
  height: 72px !important;
}

.market-modal {
  height: 380px !important;
}

.limit-modal {
  height: 440px !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-back-button {
  z-index: 1;
}

.header-minimize-button {
  z-index: 1;
}

.order-type-container {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
}

.side-button {
  border-radius: 54px !important;
  min-height: 24px !important;
  padding: 0px !important;
  font-size: 11px !important;
  max-width: 114px;
}

.order-type-button {
  border-radius: 54px !important;
  min-height: 24px !important;
  padding: 0px !important;
  font-size: 10px !important;
  max-width: 96px;
}

.amount-input {
  width: 100%;
  height: 28px;
  padding: 10px;
  border-radius: 28px !important;
}

.amount-price {
  width: 100%;
  height: 28px;
  padding: 10px;
  border-radius: 28px !important;
}

.pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Common/Trade/components/TradingPage/index.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,uBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,wBAAA;AAGF;;AADA;EACE,wBAAA;AAIF;;AAFA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAKF;;AAFA;EACE,UAAA;AAKF;;AAFA;EACE,UAAA;AAKF;;AAHA;EACE,aAAA;EACA,6BAAA;EACA,QAAA;AAMF;;AAJA;EACE,8BAAA;EACA,2BAAA;EACA,uBAAA;EACA,0BAAA;EACA,gBAAA;AAOF;;AALA;EACE,8BAAA;EACA,2BAAA;EACA,uBAAA;EACA,0BAAA;EACA,eAAA;AAQF;;AALA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AAQF;;AALA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AAQF;;AALA;EACE,eAAA;AAQF","sourcesContent":[".trading-view {\r\n  min-height: 92vh !important;\r\n  height: 92vh !important;\r\n}\r\n.minimized-modal {\r\n  height: 72px !important;\r\n}\r\n.market-modal {\r\n  height: 380px !important;\r\n}\r\n.limit-modal {\r\n  height: 440px !important;\r\n}\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.header-back-button {\r\n  z-index: 1;\r\n}\r\n\r\n.header-minimize-button {\r\n  z-index: 1;\r\n}\r\n.order-type-container {\r\n  display: flex;\r\n  justify-content: space-evenly;\r\n  gap: 4px;\r\n}\r\n.side-button {\r\n  border-radius: 54px !important;\r\n  min-height: 24px !important;\r\n  padding: 0px !important;\r\n  font-size: 11px !important;\r\n  max-width: 114px;\r\n}\r\n.order-type-button {\r\n  border-radius: 54px !important;\r\n  min-height: 24px !important;\r\n  padding: 0px !important;\r\n  font-size: 10px !important;\r\n  max-width: 96px;\r\n}\r\n\r\n.amount-input {\r\n  width: 100%;\r\n  height: 28px;\r\n  padding: 10px;\r\n  border-radius: 28px !important;\r\n}\r\n\r\n.amount-price {\r\n  width: 100%;\r\n  height: 28px;\r\n  padding: 10px;\r\n  border-radius: 28px !important;\r\n}\r\n\r\n.pointer {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
