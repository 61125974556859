/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { changeRequireTwoFA } from "redux/actions/currentUser";
import { changeTours } from "redux/actions/currentUser";

// sweetalert2 components
import Swal from "sweetalert2";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Tour State
import { setInitialTourState, disableTourState } from "utils/tourState";

// Translate
import { useTranslation } from "react-i18next";

function PlatformSettings() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { require_two_fa, tours } = useSelector((state) => state.currentUser.data);

  // const [followsMe, setFollowsMe] = useState(true);
  // const [answersPost, setAnswersPost] = useState(false);
  // const [newLaunches, setNewLaunches] = useState(false);
  // const [productUpdate, setProductUpdate] = useState(true);
  // const [newsletter, setNewsletter] = useState(true);

  const disable2FA = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    require_two_fa
      ? newSwal
        .fire({
          title: t("Are you sure?"),
          text: t("Do you really want to disable 2FA?"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: t("Yes, disable 2FA!"),
          cancelButtonText: t("No, keep 2FA"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            dispatch(changeRequireTwoFA(!require_two_fa))
              .then(() => {
                Swal.fire(
                  t("2FA Disabled!"),
                  t(
                    "Your two-factor authentication has been successfully disabled."
                  ),
                  "success"
                );
              })
              .catch(() => {
                Swal.fire(
                  t("Oops..."),
                  t(
                    "There was an error disabling 2FA. Please try again or contact support if the issue persists."
                  ),
                  "error"
                );
              });
          }
        })
      : dispatch(changeRequireTwoFA(!require_two_fa));
  };

  const disableTour = () => {
    dispatch(changeTours(!tours))
  }

  useEffect(() => {
    tours ? setInitialTourState() : disableTourState();
  }, [tours, setInitialTourState, disableTourState]);

  return (
    <Card>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          {t("Settings")}
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
        <SoftTypography
          variant="caption"
          fontWeight="bold"
          color="text"
          textTransform="uppercase"
        >
          {t("Platform")}
        </SoftTypography>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch checked={require_two_fa} onChange={disable2FA} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Two-factor authentication")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch checked={tours} onChange={disableTour} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Tours")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        {/* <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          {t("Platform")}
        </SoftTypography>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch disabled checked={followsMe} onChange={() => setFollowsMe(!followsMe)} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Remove small balances")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch disabled checked={answersPost} onChange={() => setAnswersPost(!answersPost)} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Base token")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={3}>
          <SoftTypography
            variant="caption"
            fontWeight="bold"
            color="text"
            textTransform="uppercase"
          >
            {t("Notifications")}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch disabled checked={newLaunches} onChange={() => setNewLaunches(!newLaunches)} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Trade opened")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch disabled checked={productUpdate} onChange={() => setProductUpdate(!productUpdate)} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Trade closed")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch disabled checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {t("Arbitrage opportunity found")}
            </SoftTypography>
          </SoftBox>
        </SoftBox> */}
      </SoftBox>
    </Card>
  );
}

export default PlatformSettings;
