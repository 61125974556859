import Binance from 'assets/logos/Binance.png';
import AscendEx from 'assets/logos/AscendEx.png';
import Kraken from 'assets/logos/Kraken.png';
import KuCoin from 'assets/logos/KuCoin.png';
import OKEx from 'assets/logos/OKEx.png';
import ProBit from 'assets/logos/ProBit.png';
import HitBtc from 'assets/logos/HitBtc.png';
import ByBit from 'assets/logos/ByBit.png'
// import Whitebit from 'assets/logos/Whitebit.png'
import Bitget from 'assets/logos/Bitget.png'

export const exchangesData = [
    {
        value: "binance",
        label: "Binance",
        cryptoCompareLabel: "Binance",
        img: Binance,
        publicKeyName: "binancePublic",
        privateKeyName: "binancePrivate",
        activeName: "binanceActive",
        referalLink: "https://accounts.binance.com/es/register?ref=37344351",
        needsPassword: false
    },
    {
        value: "hitbtc",
        label: "HitBtc",
        cryptoCompareLabel: "HitBTC",
        img: HitBtc,
        publicKeyName: "hitbtcPublic",
        privateKeyName: "hitbtcPrivate",
        activeName: "hitbtcActive",
        referalLink: "https://hitbtc.com/signupapp",
        needsPassword: false
    },
    {
        value: "ascendex",
        label: "AscendEx",
        cryptoCompareLabel: "ascendex",
        img: AscendEx,
        publicKeyName: "ascendexPublic",
        privateKeyName: "ascendexPrivate",
        activeName: "ascendexActive",
        referalLink: "https://ascendex.com/en-us/register?inviteCode=URY4WDPL0",
        needsPassword: false
    },
    {
        value: "kucoin",
        label: "KuCoin",
        cryptoCompareLabel: "Kucoin",
        img: KuCoin,
        publicKeyName: "kucoinPublic",
        privateKeyName: "kucoinPrivate",
        passwordName: "kucoinPassword",
        activeName: "kucoinActive",
        referalLink: "https://www.kucoin.com/ucenter/signup?rcode=2Mr6s3M",
        needsPassword: true
    },
    {
        value: "okx",
        label: "okx",
        cryptoCompareLabel: "OKEX",
        img: OKEx,
        publicKeyName: "okexPublic",
        privateKeyName: "okexPrivate",
        passwordName: "okexPassword",
        activeName: "okexActive",
        referalLink: "https://www.okx.com/join/63259712",
        needsPassword: true
    },
    {
        value: "probit",
        label: "ProBit",
        cryptoCompareLabel: "probit",
        img: ProBit,
        publicKeyName: "probitPublic",
        privateKeyName: "probitPrivate",
        activeName: "probitActive",
        referalLink: "https://www.probit.com/r/4740167",
        needsPassword: false
    },
    {
        value: "bybit",
        label: "Bybit",
        cryptoCompareLabel: "bybit",
        img: ByBit,
        publicKeyName: "bybitPublic",
        privateKeyName: "bybitPrivate",
        activeName: "bybitActive",
        referalLink: "https://www.bybit.com/invite?ref=09WJAK",
        needsPassword: false
    },
    {
        value: "kraken",
        label: "Kraken",
        cryptoCompareLabel: "Kraken",
        img: Kraken,
        publicKeyName: "krakenPublic",
        privateKeyName: "krakenPrivate",
        activeName: "krakenActive",
        referalLink: "https://www.kraken.com/es-es/sign-up",
        needsPassword: false
    },
    // {
    //     value: "whitebit",
    //     label: "WhiteBit",
    //     cryptoCompareLabel: "?",
    //     img: Whitebit,
    //     publicKeyName: "krakenPublic",
    //     privateKeyName: "krakenPrivate",
    //     activeName: "krakenActive",
    //     referalLink: "https://www.kraken.com/es-es/sign-up",
    //     needsPassword: false
    // },
    {
        value: "bitget",
        label: "Bitget",
        cryptoCompareLabel: "?",
        img: Bitget,
        publicKeyName: "bitgetPublic",
        privateKeyName: "bitgetPrivate",
        activeName: "bitgetActive",
        referalLink: "https://partner.bitget.com/bg/m9x6n96y",
        needsPassword: true
    }
]