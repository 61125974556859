import React, { useState, useEffect } from 'react'

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import ExchangeIcon from "examples/Icons/Exchange";
import { addBot } from 'api/bots';
import { deleteBotCopyTrade } from 'api/bots';
import { STATUS } from 'constants/fetchStatus';
import StatusAnimations from 'componentsCustom/StatusAnimations';

// Translate
import { useTranslation } from "react-i18next";

const ExchangeCard = ({ exchange }) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(!!exchange.suscription_id);
    const [amount, setAmount] = useState(exchange.amount);
    const [exchangeStatus, setStatus] = useState(STATUS.PENDING);
    const [inputError, setInputError] = useState(false);

    const submitActive = async () => {
        if (amount) {
            setStatus(STATUS.LOADING)
            if (active) {
                const { success } = await deleteBotCopyTrade(exchange.suscription_id);
                setActive(!success);
                setStatus(success ? STATUS.OK : STATUS.ERROR);
            } else {
                const { success } = await addBot({ bot_id: 1, exchange: exchange.value, amount })
                setActive(success);
                setStatus(success ? STATUS.OK : STATUS.ERROR);
            }
        } else {
            setInputError(true)
            setTimeout(() => {
                setInputError(false);
            }, 6000);
        }
    };

    return (
        <SoftBox width="100%">
            <Card sx={{ p: 1, bgcolor: "#ffffff00" }}>
                <StatusAnimations status={exchangeStatus} setStatusFunction={setStatus} />
                {exchangeStatus === STATUS.PENDING && (
                    <Grid
                        container
                        justifyContent={{ xs: "space-between", md: "space-around" }}
                        alignItems="center"
                    >
                        <Grid item xs={4} md={4}>
                            <ExchangeIcon icon={exchange.img} label={exchange.label} />
                        </Grid>
                        <Grid item md={4}>
                            <SoftBox display="flex" justifyContent="flex-start" alignItems="center" gap={1} >
                                <SoftBox>
                                    <Switch disabled={exchangeStatus === STATUS.LOADING} checked={active} onChange={submitActive} />
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                        <Grid item md={4}>
                            <SoftInput
                                required
                                type="number"
                                size="small"
                                placeholder={t("Amount")}
                                icon={{
                                    component: <AttachMoneyIcon />,
                                    direction: "left",
                                }}
                                error={inputError}
                                value={amount}
                                onChange={({ target }) => !active && setAmount(target.value)}
                                disabled={active}
                            />
                        </Grid>
                    </Grid>
                )}
            </Card>
        </SoftBox>
    )
}

export default ExchangeCard;