// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animation {
    display: inline-block;
    padding: 0px !important;
    margin: 0px !important;
    width: 54px; 
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Common/Status/Animation/styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".animation {\r\n    display: inline-block;\r\n    padding: 0px !important;\r\n    margin: 0px !important;\r\n    width: 54px; \r\n    height: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
