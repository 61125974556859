import React, { useState, useEffect } from 'react'

// Redux
import { useSelector } from "react-redux";
import { addBot } from 'api/bots';
import { deleteBotCopyTrade } from 'api/bots';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from 'components/SoftInput';

// Soft UI Dashboard PRO Colors
import colors from 'assets/theme/base/colors';

// Data
import { exchangesData } from "data/exchanges";

// Copy trade components
import ExchangeCard from './ExchangeCard';

// Translate
import { useTranslation } from "react-i18next";
import SoftButton from 'components/SoftButton';

const Bot = () => {
    const { t } = useTranslation();
    // const keysData = useSelector((state) => state.keys.data);
    const botsData = useSelector((state) => state.bots.data);
    const bot = botsData.find((bot) => bot.bot_id === 1);
    // const [activeExchanges, setExchanges] = useState([]);
    const [state, setState] = useState(!!bot);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(bot?.amount || "0");
    const [inputError, setInputError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const tempState = !state;
        setLoading(true);
        if (tempState) {
            const { success } = await addBot({ bot_id: 1, exchange: null, amount })
            setState(success)
        } else {
            const suscriptionId = bot.suscription_id || null;
            if (suscriptionId) {
                const { success } = await deleteBotCopyTrade(suscriptionId);
                if (success) {
                    setState(false);
                    setAmount("0");
                }
            }
        }
        setLoading(false);
    }

    // useEffect(() => {
    //     setState(!!botsData.find((bot) => bot.bot_id === 1));
    //     if (keysData.length) {
    //         const activeExchanges = keysData.filter(({ isActive }) => isActive)
    //         setExchanges(activeExchanges.map(({ exchange }) => {
    //             const { label, img, value } = exchangesData.find(({ value }) => value === exchange);
    //             const bot = botsData.find((bot) => bot.exchange === exchange && bot.bot_id === 1);
    //             return { value, label, img, suscription_id: bot?.suscription_id || null, amount: bot?.amount || "" }
    //         }))
    //     }
    // }, [keysData, botsData, setExchanges]);

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox
                component="form"
                p={3}
                height="100%"
                bgColor={state ? "info" : "white"}
                variant="gradient"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                onSubmit={handleSubmit}
            >
                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                >
                    <SoftTypography variant="body2" color={state ? "white" : "text"} fontWeight="bold">
                        {t(state ? "On" : "Off")}
                    </SoftTypography>
                    <SoftBox>
                        <Switch disabled={loading} checked={state} type="submit" />
                    </SoftBox>
                </SoftBox>
                {
                    state ? (
                        <QueryStatsSharpIcon
                            fontSize="large"
                            sx={{
                                bgcolor: colors.white.main,
                                p: 1,
                                height: "50px",
                                width: "50px",
                                borderRadius: 50
                            }}
                        />
                    ) : (
                        <QueryStatsSharpIcon
                            fontSize="large"
                            color="white"
                            sx={{
                                bgcolor: colors.dark.main,
                                p: 1,
                                height: "50px",
                                width: "50px",
                                borderRadius: 50
                            }}
                        />
                    )
                }
                <SoftBox mt={1} lineHeight={1}>
                    <SoftTypography
                        variant="body2"
                        color={state ? "white" : "text"}
                        textTransform="capitalize"
                        fontWeight="medium"
                    >
                        {t("Bot")}
                    </SoftTypography>
                    <SoftTypography variant="caption" color={state ? "white" : "text"}>
                        {t("To use the Bot, ensure that at")}{" "}
                        <strong>{t("least one exchange is active")}</strong>.{" "}
                        {t("The bot will automatically operate across all active exchanges, mirroring trading strategies and executing trades on the selected pairs. Please note that the minimum amount required to activate the bot is")} {" "}
                        <strong>{t("500 USD")}</strong>.{" "}
                    </SoftTypography>
                    <SoftBox
                        gap={3}
                        mt={4}
                        display="flex"
                        flexDirection="column"
                    >
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📈 <strong>{t("Gross Profit")} (%): 22.81</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📊 <strong>{t("Sharpe Ratio")}: 3.16</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📉 <strong>{t("Sortino Ratio")}: 1.00</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📉 <strong>{t("Maximum Drawdown")} (%): -8.86</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            🚀 <strong>{t("Maximum Run-Up")} (%): 30.55</strong>
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                {/* <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                >
                    {activeExchanges.map((exchahge, index) => (
                        <ExchangeCard
                            key={index}
                            exchange={exchahge}
                            isBotActive={state}
                            setBotActive={setState}
                        />
                    ))}
                </SoftBox> */}
            </SoftBox>
        </Card>
    )
};

export default Bot;