// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
    font-size: 45px !important;
    font-weight: 900 !important;
    text-align: center !important;
}
.publishing-date {
    text-align: right !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-right: 11px !important;
}
.news-title {
    position: absolute;
    margin: 20px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: white !important;
}
.news-author {
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    padding-bottom: 11px !important;
    margin: 16px !important;
}
.card-news {
    height: 500px !important;
}
.descripcion {
    height: 254px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Common/News/index.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;IACI,4BAA4B;IAC5B,0BAA0B;IAC1B,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,0BAA0B;IAC1B,2BAA2B;IAC3B,uBAAuB;AAC3B;AACA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,oBAAoB;IACpB,+BAA+B;IAC/B,uBAAuB;AAC3B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".page-title {\r\n    font-size: 45px !important;\r\n    font-weight: 900 !important;\r\n    text-align: center !important;\r\n}\r\n.publishing-date {\r\n    text-align: right !important;\r\n    font-size: 14px !important;\r\n    font-weight: 500 !important;\r\n    margin-right: 11px !important;\r\n}\r\n.news-title {\r\n    position: absolute;\r\n    margin: 20px !important;\r\n    font-size: 16px !important;\r\n    font-weight: 500 !important;\r\n    color: white !important;\r\n}\r\n.news-author {\r\n    position: absolute !important;\r\n    right: 0 !important;\r\n    bottom: 0 !important;\r\n    padding-bottom: 11px !important;\r\n    margin: 16px !important;\r\n}\r\n.card-news {\r\n    height: 500px !important;\r\n}\r\n.descripcion {\r\n    height: 254px;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    white-space: wrap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
