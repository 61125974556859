import { SMARTS_CONTRACTS, NETWORK as network } from "apiconfig";

export const getPaywallConfig = () => {
  const locks = {};
  const metadataInputs = [
    {
      name: "Full Name",
      defaultValue: "",
      type: "text",
      required: false,
      placeholder: "John Doe",
      public: false
    },
    {
      name: "Email",
      defaultValue: "",
      type: "email",
      required: false,
      placeholder: "john@mail.xyz",
      public: false
    },
  ];

  SMARTS_CONTRACTS.forEach(lock => {
    locks[lock] = {
      network,
    }
  });

  return {
    locks,
    metadataInputs,
    title: "CTrading",
    icon: "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=140,fit=crop,q=95/mnl5vWg42Dc9JMeq/logoctrading-Yle5vWaMqWu4aygD.png",
    // persistentCheckout: true,
    pessimistic: true,
    hideSoldOut: true,
  };
}

