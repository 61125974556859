import axios from "axios";
import { BACK_API } from "apiconfig";
import { connect, disconnect, readContract, fetchTransaction } from '@wagmi/core'
import { InjectedConnector } from '@wagmi/core/connectors/injected'
import { PublicLockV13 } from '@unlock-protocol/contracts';
import { Paywall } from "@unlock-protocol/paywall";
import { networks } from "@unlock-protocol/networks";
import { getPaywallConfig } from "utils/unlockConfig";

const errorContract = {
    success: false,
    data: "Error getting contract, please try again."
};

function getContract(data) {
    const { id, jwtToken } = data;
    const url = `${BACK_API}/contract?user_id=${id}`;
    const headers = {
        'token': jwtToken,
        'id': id
    };
    return axios
        .get(url, { headers })
        .then(({ data }) => {
            return {
                success: true,
                data,
            }
        })
        .catch(err => {
            return {
                success: false,
                data: err.response.data.error
            };
        });
};

function postContract(data, wallet, contract, chainId, duration, expiration_date, price) {
    const { id, jwtToken } = data;
    const url = `${BACK_API}/contract`;
    const headers = {
        'token': jwtToken,
        'id': id
    };
    const body = {
        user_id: id,
        wallet,
        contract,
        chainId,
        duration,
        expiration_date,
        price
    };
    return axios
        .post(url, body, { headers })
        .then(({ data }) => {
            return {
                success: true,
                data
            };
        })
        .catch(err => {
            return {
                success: false,
                data: err.response.data.error
            };
        });
};

async function connectWallet() {
    await disconnect();
    return connect({
        connector: new InjectedConnector(),
    })
        .then(({ account }) => ({ account }))
        .catch(() => {
            throw new Error('metamask')
        });
};

async function readingContract(wallet, address) {
    const abi = PublicLockV13.abi;
    const args = [wallet];
    return Promise.all([
        readContract({
            address,
            abi,
            functionName: "balanceOf",
            args,
        })
            .then(data => !data.isZero()),
        readContract({
            address,
            abi,
            functionName: "expirationDuration",
        })
            .then(data => {
                if (data.toHexString() === "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff") {
                    return { duration: null, expiration_date: null }; // Unlimited duration
                } else {
                    const duration = data.toNumber() / (24 * 60 * 60);
                    const date = new Date(Date.now());
                    date.setDate(date.getDate() + duration);
                    const expiration_date = date.getTime();
                    return { duration, expiration_date };
                };
            }),
        readContract({
            address,
            abi,
            functionName: "keyPrice",
        })
            .then(data => {
                // const intValue = BigInt(data.toHexString()) / BigInt(10 ** 15);
                const intValue = parseInt(data.toHexString(), 16) / (10 ** 15);
                const decimalNumber = Number(intValue) / 1000;
                return Number(decimalNumber.toFixed(3));
            }),
    ])
        .then(([isInEffect, { duration, expiration_date }, price]) => ({ isInEffect, duration, expiration_date, price }))
        .catch((err) => {
            console.log(err);
            throw new Error('Error reading contract, please try again.')
        });
};


async function checkoutContract() {
    const paywallConfig = getPaywallConfig();
    const paywall = new Paywall(paywallConfig, networks);
    return paywall.loadCheckoutModal()
        .then(data => data);
};

async function checkoutProccess(data) {
    await connectWallet();
    const { hash, lock } = await checkoutContract();
    if (lock) {
        const { from, chainId } = await fetchTransaction({
            hash,
        })
            .then(data => data)
            .catch(() => {
                throw new Error('Invalid hash, please try again.')
            });
        const { duration, expiration_date, price } = await readingContract(from, lock);
        return await postContract(data, from, lock, chainId, duration, expiration_date, price)
            .then(() => ({ success: true, data }))
            .catch(() => {
                throw new Error('Error getting contract, please try again.')
            });
    } else {
        return errorContract;
    }
};

export async function unlockProtocolActions(data) {
    try {
        const { require_unlock } = data;
        if (!require_unlock) {
            return {
                success: true,
                data
            };
        } else {
            // Unlock Protocol.
            // Get the contract associated with the user's email.
            const lock = await getContract(data);
            const { success } = lock;

            if (success) {
                // If the contract retrieval was successful.
                const { isInEffect } = await readingContract(lock.data.wallet, lock.data.contract);
                if (isInEffect) {
                    // If the contract is in effect.
                    return {
                        success: true,
                        data
                    };
                } else {
                    // If the contract is not in effect, proceed with checkout.
                    return await checkoutProccess(data);
                }
            } else {
                //If the user does not have signed contracts, proceed with checkout.
                return await checkoutProccess(data);
            };
        }
    } catch (error) {
        return {
            success: false,
            data: error
        };;
    }
};
