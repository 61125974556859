// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Bots components
import Bot from "./components/Bot";
import useIsUserFreemium from "utils/useUserSubscriptionStatus";
import UpgradePremium from "componentsCustom/UpgradePremium";

function Bots({ }) {
    const isFreemium = useIsUserFreemium();
    return (
        <DashboardLayout>
            {
                isFreemium ?
                    <UpgradePremium /> :
                    <SoftBox component={Grid} container p={4} spacing={3}>
                        <SoftBox component={Grid} item xs={12} lg={6} p={4}>
                            <Bot />
                        </SoftBox>
                    </SoftBox>
            }
        </DashboardLayout>
    );
};

export default Bots;
