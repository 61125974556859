// react-router components
import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { Stack } from "@mui/material";
import { ROUTES } from "constants";

// Translate
import { useTranslation } from "react-i18next";

export default function UpgradePremium() {
    const { t } = useTranslation();
    return (
        <Stack
            spacing={2}
            sx={{ margin: 5 }}
            justifyContent="center"
            alignItems="center"
        >
            <SoftTypography
                className="amount-card"
                ownerState={{ verticalAlign: "baseline" }}
            >
                {t("You need the premium version to use this feature!")}!
            </SoftTypography>
            <SoftButton
                component={Link}
                state={{ tab: "plans" }}
                to={ROUTES.PROFILE}
                style={{ marginTop: "20px", align: "center", width: "auto" }}
                variant="gradient"
                color="info"
            >
                <SoftTypography variant="button" color="white">
                    {t("Upgrade to premium")}
                </SoftTypography>
            </SoftButton>
        </Stack>
    );
}
